import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import createSlug from './../../services/createSlug';
import { getTranslate } from './../../services/getTranslate';
import { Router } from '@angular/router';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})

export class MapComponent implements OnInit {
  lang$:Observable<string>
  translates$:Observable<string>
  locals$:Observable<string>
  atractions$:Observable<object>

  currentLang = 'pl';
  translates = null;
  localities = [];
  localitiesData = null;
  atractionsData = null;
  atractions = [];
  hiddenNavigation = false;
  atractionsLocal = [];
  openedLocal: object;
  vrPins = []

  constructor(private router: Router, private store: Store<{ lang: string, translates: string, locals: string, atractions: object }>) {
    this.lang$ = store.pipe(select('lang'));
    this.translates$ = store.pipe(select('translates'));
    this.locals$ = store.pipe(select('locals'));
    this.atractions$ = store.pipe(select('atractions'));

    store.select('lang').subscribe((val) => {
      if(this.localitiesData) {
        this.localities = this.localitiesData.filter(item => (item.language).toLowerCase() == val);
        let tempVr =   this.localities.filter(item => item.gallery_3d_ids.length > 0);
        tempVr.map(el => {
          el.gallery_3d_ids.map(e => {
            this.vrPins.push( { name: el.name, id: el.id, item: e});
          })
        })
        this.hiddenNavigation = false;
      }
    })
  }
  

  ngOnInit(): void {
    this.lang$.subscribe(currentLang => this.currentLang = currentLang);
    this.translates$.subscribe(translates => this.translates = translates);
    this.getLocalities();
    this.removeVrClass();
  }

  removeVrClass = () => {
    if(typeof window !== "undefined") {
      var item = document.querySelector(".a-fullscreen");
      if(item && item.classList) {
        item.classList.remove("a-fullscreen")
      }
    }
  }

  createSlug = (text) => {
    return createSlug(text);
  }

  getString = (stringName) => {
    return getTranslate(stringName, this.translates, this.currentLang);
  }

  getLocalities = async() => {
    this.locals$.subscribe(local => {
      this.localitiesData = local;
      this.localities = this.localitiesData.filter(item => (item.language).toLowerCase() == this.currentLang);
      let tempVr =   this.localities.filter(item => item.gallery_3d_ids.length > 0);
      tempVr.map(el => {
        el.gallery_3d_ids.map(e => {
          this.vrPins.push( { name: el.name, id: el.id, item: e});
        })
      })
    });

    this.atractions$.subscribe(atr => {
      this.atractionsData = atr;
      this.atractions = this.atractionsData.filter(item => (item.language).toLowerCase() == this.currentLang && item.map_lat && item.map_lng);
    });
  }

  log = (val) => console.log(val);

  defaultPositionMap = [
    {
      lat: 50.086781,
      lng: 18.092336,
      title: "Pietrowice"
    }
  ]
  zoom = 14;

  mapStyle = require('./map-style.json');

  markerClicked = (marker) => {
    this.defaultPositionMap[0].lat = marker.map_lat;
    this.defaultPositionMap[0].lng = marker.map_lng;
    this.atractionsLocal = marker.atractions_ids ? marker.atractions_ids : "";
    this.hiddenNavigation = true;
    this.openedLocal = marker
  }

  openAtraction = (atr) => {
    this.router.navigate([`/miejscowosci/miejscowosc/${createSlug(atr.name)}`], { 
      queryParams: {
        p: atr.id
      }
    });
  }

  openVr = (atr) => {
    this.router.navigate([`/miejscowosci/miejscowosc/${createSlug(atr.name)}/galeria-vr`], { 
      queryParams: {
        i: atr.item.id
      }
    });
  }
}
