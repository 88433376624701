import { Component } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {map} from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { setTranslates } from './actions/translates.actions';
import { setLocalities } from './actions/localities.actions';
import { setAtractions } from './actions/atractions.actions';
import axios from 'axios';
import ApiUrl from './services/ApiUrl';

interface AppState {
  message: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Pietrowice Wielkie';
  loader: boolean = true;

  routeUrl$:string;

  message$: Observable<string>
  translates$:Observable<string>
  
  constructor( route: Router, private store: Store<{ translates: string }> ) {
    route.events.subscribe(() => 
      this.routeUrl$ = route.url
    );
  }

  async ngOnInit() {
    await this.getTranslates();
    await this.getLocalities();
    await this.getAtractions();
    this.loader = false;
  }

  checkVr() {
    if ( this.routeUrl$.indexOf('galeria-vr') > -1) {
      return true;
    } else {
      return false;
    }
  }

  getTranslates = async() => {
    await axios({
      method: 'get',
      url: `${ApiUrl()}/showTranslateItems`,
    }).then(async response => {
        let res = JSON.parse(response.request.response);
        await this.store.dispatch(setTranslates({ name: res }));
    })
  }

  getLocalities = async() => {
    await axios({
      method: 'get',
      url: `${ApiUrl()}/localities`,
    }).then(async response => {
        let res = JSON.parse(response.request.response);
        console.log(res)
        await this.store.dispatch(setLocalities({ name: res.data }));
    })
  }

  getAtractions = async() => {
    await axios({
      method: 'get',
      url: `${ApiUrl()}/getAtractions`,
    }).then(async response => {
        let res = JSON.parse(response.request.response);
        console.log(res)
        await this.store.dispatch(setAtractions({ name: res.data }));
    })
  }
  
}
